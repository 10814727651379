.characterEditor {
  padding-bottom: 64px;
}

.maxWidthWrapper {
  position: relative;
}

.header {
  padding-bottom: 64px;
  width: 65%;
}

.title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
}

.description {
  font-size: 1.25rem;
  margin: 0;
}

.characterWrapper {
  position: fixed;
  top: 15%;
  left: 70%;
  bottom: 5%;
  min-height: 500px;

  /* how come this works exaxctly opposite from my understanding? */
  @media screen and (max-width: 1600px) {
    display: none;
  }
}

.controlColumn {
  width: 50%;
}

.perspectiveEffect {
  position: fixed;
  top: 60%;
  left: 0;
  height: 40%;
  width: 100%;
  background-color: hsl(195deg, 20%, 86%);
}
